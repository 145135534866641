import React from 'react'
import ReactDOM from 'react-dom'
import loadable from '@loadable/component'

const documentRoot = document.getElementById('root')
if (documentRoot) {
  const App = loadable(() => import('./App' /* webpackChunkName: "App" */))

  ReactDOM.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>,
    documentRoot,
  )
}

const cmsRoot = document.getElementById('cms_root')
if (cmsRoot) {
  const AppCms = loadable(() => import('./AppCms' /* webpackChunkName: "AppCms" */))

  ReactDOM.render(
    <React.StrictMode>
      <AppCms/>
    </React.StrictMode>,
    cmsRoot,
  )
}

export const gtm = (gtmContainerId = ''): void => {
  if (!gtmContainerId.startsWith('GTM-') || !document) return

  const script = document.createElement('script')
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmContainerId}');`

  document.head.insertBefore(script, document.head.childNodes[0])
}
